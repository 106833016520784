import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { globalHistory } from '@reach/router'
import loadable from '@loadable/component'
import slugify from 'slugify'

import imgix from '../utils/imgix'
import Helmet from 'react-helmet'
import FramelessLayout from '../components/layouts/FramelessLayout'
import Header from '../components/shared/Header'
import Footer from '../components/shared/Footer'

const IsotopeGrid = loadable(() => import('../components/shared/IsotopeGrid'))

export default class IndexPage extends React.Component {
  //Hack to check is user is coming from artist-detail page and scroll to #artists
  constructor() {
    super()
    if (
      globalHistory.location &&
      globalHistory.location.state &&
      globalHistory.location.state.from
    ) {
      this.artistRef = React.createRef()

      const category = globalHistory.location.state.from
      const scrollToArtists = setInterval(() => {
        if (this.artistRef.current) {
          this.artistRef.current.scrollIntoView({ behavior: 'smooth' })
          document.getElementById(`artists-${category}`).click()
          history.pushState({}, null, `/fiction-artists/#${category}`)
          clearInterval(scrollToArtists)
        }
      }, 500)
    }
  }

  render() {
    const seo = {
      title: this.props.data.wordpressPage.acf.seo_meta_title || "",
      description: this.props.data.wordpressPage.acf.seo_meta_description || "",
      robotsIndex: this.props.data.wordpressPage.acf.seo_index ? 'index' : 'noindex',
      robotsFollow: this.props.data.wordpressPage.acf.seo_follow ? 'follow' : 'nofollow',
    }
    const { nodes: artists } = this.props.data.allWordpressWpArtist
    const { acf: page } = this.props.data.wordpressPage
    const { acf: homePage } = this.props.data.home

    const categories = Array.from(
      new Set(
        artists
          .filter(artist => !artist.acf.hide_from_the_overview_page)
          .flatMap(artist => artist.artist_category_name.filter(c => c !== 'New Artist' && c !== 'Commercial Artist' && c !== 'Fiction Artist'))
      )
    )

    const items = artists
      .filter(artist => !artist.acf.hide_from_the_overview_page)
      .map(artist => {
        const category = artist.artist_category_name.map(c => slugify(c, { lower: true }))

        const profession = artist.artist_category_name
          .filter(c => c !== 'New Artist' && c !== 'Commercial Artist' && c !== 'Fiction Artist')
          .join(', ')

        const image = !(artist.acf.face && !artist.acf.face.source_url.includes('placeholder'))
          ? '/assets/images/placeholder.png'
          : imgix.buildURL(artist.acf.face.source_url, { w: 600, h: 338 })

        return {
          author: artist.title,
          authorProfession: profession,
          url: `/artist/${artist.slug}/fiction`,
          articleType: 'small',
          image,
          category
        }
      })

    return (
      <FramelessLayout>
        <Helmet
          title={seo.title}
          bodyAttributes={{
            class:
              'has-header-white has-banner has-grouped-logo-mobile has-banner-right',
          }}
        >
          <html lang="en"/>
                    { seo.description !== "" &&
          <meta name="description" content={seo.description} />
          }
          <meta name="robots" content={seo.robotsIndex + ", " + seo.robotsFollow} />
          <link rel='canonical' href='https://boldbreed.de/fiction-artists/' />
        </Helmet>

        <div className='wrapper'>
          <div className='wrapper__lines'></div>

          <div className='wrapper__inner'>
            <Header path={this.props.path} />

            <section className='section-banner section-banner--right-active overview'>
              <div className='section__image section__image--right'>
                <h3 className='section__title js-animation section__title--right'>
                  Fiction
                </h3>

                <div className='section__bg section__bg--right'>
                  <span
                    style={{
                      backgroundImage: `url(${imgix.buildURL(
                        homePage.banner_image_2.source_url,
                        {
                          w: 1200,
                          h: 900,
                        }
                      )})`,
                    }}
                  ></span>

                  <a href='#' className='btn btn--small'>
                    Enter
                  </a>
                </div>
              </div>

              <div className='section__logo'>
                <div>
                  <span
                    style={{
                      backgroundImage: 'url(/assets/images/logo-boldgreed.svg)',
                    }}
                  ></span>

                  <em>agency for creatives</em>
                </div>
              </div>
            </section>

            <section className='section-contact'>
              <div className='shell shell--narrow'>
                <header className='section__head'>
                  <h1>
                    <div
                      dangerouslySetInnerHTML={{ __html: page.block_1_heading }}
                    />
                  </h1>
                </header>

                <div className='section__content'>
                  <div
                    dangerouslySetInnerHTML={{ __html: page.block_1_text }}
                  />
                </div>
              </div>

              <Link to='/contact/' className='btn-arrow'>
                <div dangerouslySetInnerHTML={{ __html: page.block_1_cta }} />
                <span>
                  <svg
                    width='63'
                    height='34'
                    viewBox='0 0 63 34'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M45 32L60 17L45 2'
                      stroke='#2EE862'
                      strokeWidth='3'
                    />
                    <line
                      x1='60'
                      y1='17.5'
                      y2='17.5'
                      stroke='#26ED5D'
                      strokeWidth='3'
                    />
                  </svg>
                </span>
              </Link>
            </section>

            <IsotopeGrid type='artists' items={items}>
              <div className='section__content' id='artist-list'>
                <div className='tabs'>
                  <div className='tabs__body'>
                    <div
                      id='artists'
                      ref={this.artistRef}
                      className='tab is-active'
                    >
                      <div className='filter'>
                        <ul>
                          <li className='current'>
                            <a href='#' data-category='all' id='artists-all'>
                              All Professions
                            </a>
                          </li>
                          {categories.map((category, i) => (
                            <li key={i}>
                              <a
                                href='#'
                                id={`artists-${slugify(category, {
                                  lower: true,
                                })}`}
                                data-category={slugify(category, {
                                  lower: true,
                                })}
                              >
                                {category}s
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div
                        className='grid grid--controls-overlap'
                        data-type='artists'
                      >
                        <div className='grid__inner'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </IsotopeGrid>

            <Footer />
          </div>
        </div>
      </FramelessLayout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressWpArtist: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query AudioArtistsQuery {
    wordpressPage(wordpress_id: { eq: 24305 }) {
      acf {
        block_1_heading
        block_1_text
        block_1_cta
        seo_meta_title
        seo_meta_description
        seo_index
        seo_follow
      }
    }
    home: wordpressPage(title: { eq: "Home" }) {
      acf {
        banner_image_1 {
          source_url
        }
        banner_image_2 {
          source_url
        }
      }
    }
    allWordpressWpArtist(
      sort: { fields: date, order: DESC }
      filter: { artist_category: { eq: 953 } }
    ) {
      nodes {
        id
        title
        date(formatString: "MMMM DD, YYYY")
        slug
        acf {
          face {
            source_url
          }
          hide_from_the_overview_page
        }
        artist_category_name
      }
    }
  }
`
